<template>
  <div class="app-container">
    <nav-bar />
    <router-view />
    <bottom-footer />
  </div>
</template>
<script>
import BottomFooter from "./components/BottomFooter.vue";
import NavBar from "./components/NavBar.vue";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  components: { NavBar, BottomFooter },
  mounted() {
    AOS.init();
  },
};
</script>

<style >
#app {
  font-family: System-ui;
}
* {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #439858;
  --secondary-color: #dddddd;
}

.app-default-underline {
  width: 120px;
  height: 3px;
  margin: 10px auto;
  background: var(--primary-color);
}

.section-title {
  color: var(--primary-color);
  text-align: center;
  font-size: 14px;
  text-shadow: 0 2px 2px #c2c2c2;
}

.section-sub-title {
  text-align: center;
  font-weight: 300;
  text-shadow: 0 2px 2px #c2c2c2;
  font-size: 24px;
  margin-top: 20px;
}

.other-section-title {
  text-align: center;
  color: #5e5e5e;
  font-weight: 500;
}

@media (max-width: 600px) {
  .section-sub-title {
    font-size: 22px;
    padding: 0 10px;
  }
}
</style>
