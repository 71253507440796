<template>
  <div class="testimonial">
    <h3 class="section-title">TESTIMONIALS</h3>
    <h1 class="section-sub-title">What our customers are Saying</h1>
    <div
      class="testimonial-user"
      data-aos="zoom-in-down"
      data-aos-duration="2000"
    >
      <div class="testimonial-user-left">
        <img src="../assets/userImg.png" alt="" />
      </div>
      <div class="testimonial-user-right">
        <p>
          NT SUPPLY COMPANY ltd is a good company both in design and build, we
          have worked together on more than five projects, two of which have
          been built, the others are not yet built. Its special feature is that
          it works on time, and it gives the eye a new look. I signed up to work
          with this company
        </p>
        <h3>Eng. Régis MBONIGABA</h3>
        <h4>Construction Engineer</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testimonyData: [],
    };
  },
  created() {},
};
</script>

<style scoped>
.testimonial {
  padding: 40px 0;
  background: #f2f2f2;
}

.testimonial-user {
  width: 60%;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-shadow: 2px 2px 6px #c6c6c6;
  background: #f8f8f8;
  border-radius: 5px;
}

.testimonial-user-left {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #eaeaea;
  overflow: hidden;
}
.testimonial-user-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-user-right {
  width: 80%;
}

.testimonial-user-right p {
  color: #606060;
}

.testimonial-user-right h3 {
  margin: 10px 0;
  font-weight: 500;
  color: #606060;
}
.testimonial-user-right h4 {
  margin: 10px 0;
  font-weight: 300;
}
@media (max-width: 850px) {
  .testimonial-user {
    flex-direction: column;
  }
  .testimonial-user-right {
    width: 90%;
  }
}

@media (max-width: 850px) {
  .testimonial-user {
    width: 80%;
  }
}
</style>