<template>
  <div class="our-projects">
    <div class="project-cover-img">
      <img src="../assets/NTSCltd20.png" alt="" />
      <h1>All Projects</h1>
    </div>
    <h2 class="other-section-title">Output of Our Works</h2>
    <div class="app-default-underline"></div>
    <div class="projects-container">
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd1.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd2.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd3.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd4.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd5.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd6.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd7.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd8.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd9.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd10.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd11.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd12.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd13.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd14.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd15.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
      <div class="projects-card" data-aos="fade-down" data-aos-duration="2000">
        <img src="../assets/NTSCltd17.jpg" alt="" />
        <h4>Constructed building</h4>
      </div>
    </div>
    <div class="load-more-projects"></div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  components: { BaseButton },
  data() {
    return {
      isOpenModal: false,
      selectedProject: {},
      projectsData: [],
    };
  },
  methods: {
    loadMoreProjects() {},
    viewMoreDetails(item) {
      this.selectedProject = item;
      this.isOpenModal = true;
    },
    closeModal() {
      this.isOpenModal = false;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {},
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.our-projects {
  padding-top: 80px;
}

.project-cover-img {
  width: 100%;
}

.project-cover-img img {
  width: 100%;
  height: 25vh;
}

.project-cover-img h1 {
  position: absolute;
  top: 17%;
  left: 40px;
  color: white;
  -webkit-text-stroke: 1px black;
  font-size: 60px;
}

.other-section-title {
  margin-top: 30px;
}

.app-default-underline {
  margin-bottom: 20px;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 80%;
  margin: 0 auto 30px;
}

.projects-card {
  width: 100%;
  height: 250px;
  cursor: pointer;
  position: relative;
}

.projects-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projects-card h4 {
  position: absolute;
  bottom: 40px;
  left: 20px;
  color: white;
  text-transform: uppercase;
  font-weight: 200;
  z-index: 50;
}

.view-details-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.view-details-btn:hover {
  background: rgb(0, 0, 0, 0.7);
  z-index: 50;
}

.view-details-btn button {
  background: transparent;
  color: var(--primary-color);
  text-decoration: underline;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 50;
}

.projects-card-contant {
  padding: 10px 10px 0;
}

.load-more-projects {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  .project-cover-img h1 {
    font-size: 50px;
    top: 20%;
  }
}
@media (max-width: 460px) {
  .project-cover-img h1 {
    font-size: 40px;
    top: 22%;
  }
}

@media (max-width: 400px) {
  .project-cover-img h1 {
    font-size: 30px;
    -webkit-text-stroke: 1px white;
    top: 22%;
  }
}
</style>