<template>
  <div class="process">
    <h3 class="section-title">PROCESS</h3>
    <h1 class="section-sub-title">How it Works</h1>
    <div
      class="process-card-container"
      data-aos="fade-down"
      data-aos-duration="1000"
    >
      <div class="process-card">
        <h4>Meet the client</h4>
        <p>Meeting with a client with an idea to execute</p>
      </div>
      <div class="process-card spl-bg">
        <h4>Planning process</h4>
        <p>We work with client to develop that idea</p>
      </div>
      <div class="process-card">
        <h4>Implementation start</h4>
        <p>After Negotiation and Discussion, Implementation starts</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Process",
};
</script>

<style scoped>
.process {
  padding: 40px 20px;
  background: #f2f2f2;
}
.process-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}
.process-card {
  box-shadow: 2px 3px 6px #d4d4d4;
  width: 250px;
  margin: 20px;
  padding: 10px;
  text-align: center;
  border: 1px solid var(--primary-color);
}

.spl-bg {
  background: var(--primary-color);
  color: #ffffff;
}

.process-card h4 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.process-card p {
  font-weight: 300;
}

@media (max-width: 500px) {
  .process-card-container {
    width: 100%;
  }
  .process-card {
    width: 100%;
  }
}
</style>