<template>
  <button :style="{ borderRadius: onBorder }" @click="onClicking">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    to: {
      type: String,
      default: "",
    },
    action: {
      type: Function,
    },
    text: {
      type: String,
      default: "More",
    },
    onBorder: {
      type: String,
      default: "6px",
    },
  },
  methods: {
    onClicking() {
      if (this.to) {
        location.href = this.to;
      } else {
        this.action();
      }
    },
  },
};
</script>

<style scoped>
button {
  background: var(--primary-color);
  min-width: 80px;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  box-shadow: 2px 3px 6px #c9c9c9;
  transition: all 0.5s ease-in-out;
}

button:hover {
  transform: scale(1.1);
}
</style>