<template>
  <div class="navbar-container">
    <div class="navbar">
      <div class="navbar-left">
        <a href="/">
          <img src="../assets/ntsupplycompanylogo.png" alt="" />
        </a>
      </div>
      <div class="navbar-middle">
        <ul>
          <li>
            <router-link :to="{ name: 'home' }">HOME</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'about' }">ABOUT US</router-link>
          </li>
          <li>
            <!-- <router-link :to="{ name: 'services' }">SERVICES</router-link> -->
            <a href="/#services">SERVICES</a>
          </li>
          <li>
            <router-link :to="{ name: 'projects' }">PROJECTS</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'contactUs' }">CONTACT US</router-link>
          </li>
        </ul>
      </div>
      <div class="navbar-right">
        <a href="mailto:visionconstructionbureau@gmail.com" target="_blank">
          <i class="fa-solid fa-envelope"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-instagram"></i>
        </a>
        <!-- <i class="fa-solid fa-phone"></i> -->
        <a href="https://wa.me/250783290624" target="_blank">
          <i class="fa-brands fa-whatsapp"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-twitter"></i>
        </a>
      </div>
      <div class="navbar-menu-handler">
        <i
          class="fa-solid fa-bars"
          v-if="!isOpenMenu"
          @click="isOpenMenu = !isOpenMenu"
        ></i>
        <i
          class="fa-solid fa-xmark"
          v-if="isOpenMenu"
          @click="isOpenMenu = !isOpenMenu"
        ></i>
      </div>
    </div>
    <div class="nav-drop-down" :class="isOpenMenu ? 'show' : 'hide'">
      <ul>
        <li @click="isOpenMenu = false">
          <router-link :to="{ name: 'home' }">HOME</router-link>
        </li>
        <li @click="isOpenMenu = false">
          <router-link :to="{ name: 'about' }">ABOUT US</router-link>
        </li>
        <li @click="isOpenMenu = false">
          <!-- <router-link :to="{ name: 'services' }">SERVICES</router-link> -->
          <a href="/#services">SERVICES</a>
        </li>
        <li @click="isOpenMenu = false">
          <router-link :to="{ name: 'projects' }">PROJECTS</router-link>
        </li>
        <li @click="isOpenMenu = false">
          <router-link :to="{ name: 'contactUs' }">CONTACT US</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      isOpenMenu: false,
    };
  },
};
</script>

<style scoped>
.navbar-container {
  position: fixed;
  z-index: 200;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0 5px 8px #d7d7d7;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  /* backdrop-filter: blur(20px); */
}

.navbar-left img {
  height: 70px;
}
.navbar-middle ul {
  display: flex;
  align-items: center;
}
.navbar-middle ul li {
  list-style: none;
  margin: auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-middle ul li a {
  text-decoration: none;
  color: var(--primary-color);
  /* text-shadow: 0 2px 2px #c2c2c2; */
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}

.navbar-middle ul li a:after,
.nav-drop-down ul li a:after {
  display: block;
  content: "";
  border-bottom: solid 2px #439858;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navbar-middle ul li a:hover:after,
.nav-drop-down ul li a:hover:after {
  transform: scaleX(1);
}
.navbar-middle ul li a:after,
.nav-drop-down ul li a:after {
  transform-origin: 100% 50%;
}
.navbar-middle ul li a:after,
.nav-drop-down ul li a:after {
  transform-origin: 0% 50%;
}

.navbar-right {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.navbar-right i {
  color: var(--primary-color);
  margin-left: 3px;
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 50%;
}
.navbar-right i:hover {
  background: var(--primary-color);
  color: white;
}

.navbar-menu-handler {
  display: none;
  cursor: pointer;
  font-size: 25px;
}

.nav-drop-down {
  display: none;
  justify-content: center;
}

.nav-drop-down ul li {
  list-style: none;
  margin: 8px 0;
  width: fit-content;
}

.nav-drop-down ul li a {
  color: black;
  text-decoration: none;
  font-size: 12px;
}

.hide {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

.show {
  padding-bottom: 20px;
  position: relative;
  height: 160px;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

@media (max-width: 900px) {
  .navbar-middle,
  .navbar-right {
    display: none;
  }
  .navbar-menu-handler {
    display: flex;
  }
  .nav-drop-down {
    display: flex;
  }
}
</style>