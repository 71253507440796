<template>
  <div class="swipper-cont-section">
    <swiper
      :slidesPerView="1"
      :spaceBetween="30"
      :loop="true"
      :pagination="{
        clickable: true,
      }"
      :autoplay="true"
      :navigation="true"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide>
        <div class="carousel-container">
          <img src="../assets/NTSCltd23.png" alt="" />
        </div>
        <div class="img-backdrop"></div>
        <div class="carousel-contant">
          <h1>Green Building Innovators</h1>
          <p>
            Provide construction that prioritizes environmental sustainability
            and energy efficiency that results to the Green city
          </p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="carousel-container">
          <img src="../assets/NTSCltd24.jpeg" alt="" />
        </div>
        <div class="carousel-contant">
          <h1>Your Gateway to Exceptional Living</h1>
          <p>You are in the real place, You can have the home of your dream.</p>
        </div>
        <div class="img-backdrop"></div>
      </swiper-slide>
      <swiper-slide>
        <div class="carousel-container">
          <img src="../assets/NTSCltd19.png" alt="" />
        </div>
        <div class="carousel-contant">
          <h1>Industrial Buildings Experts</h1>
          <p>
            We construct and design the best industrial Building of your desire
          </p>
        </div>
        <div class="img-backdrop"></div>
      </swiper-slide>
      <swiper-slide>
        <div class="carousel-container">
          <img src="../assets/NTSCltd15.jpg" alt="" />
        </div>
        <div class="carousel-contant">
          <h1>Where Vision Meets Reality</h1>
          <p>
            Through construction and designing we execute the best of your dream
          </p>
        </div>
        <div class="img-backdrop"></div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import "./style.css";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";

export default {
  name: "SlideShow",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      slideImages: [],
      isFetchDone: false,
    };
  },
  created() {},
};
</script>

<style scoped>
.swipper-cont-section {
  padding-top: 80px;
}
.carousel-container {
  width: 100%;
  height: calc(100vh - 80px);
}

.carousel-container img {
  width: 100%;
  height: 100%;
}

.img-backdrop {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  background: rgba(35, 59, 87, 0.45);
  z-index: 40;
}

.carousel-contant {
  position: absolute;
  left: 40px;
  width: 50%;
  z-index: 50 !important;
}

.carousel-contant h1,
.carousel-contant p {
  color: white;
  font-size: 60px;
  text-align: left;
  margin-left: 30px;
}

.carousel-contant h1 {
  -webkit-text-stroke: 1px black;
  font-size: 60px;
}
.carousel-contant p {
  font-size: 25px;
  font-weight: 400;
  width: 80%;
}
@media (max-width: 800px) {
  .carousel-contant {
    width: 70%;
  }
  .carousel-contant h1 {
    font-size: 40px;
  }
  .carousel-contant p {
    font-size: 20px;
  }
}
@media (max-width: 400px) {
  .carousel-contant {
    width: 80%;
    left: 0;
    margin-left: 20px;
  }
}
</style>