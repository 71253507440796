<template>
  <div class="bottom-ft">
    <div class="bottom-ft-shaow"></div>
    <div class="bottom-ft-header">
      <div class="bottom-ft-left">
        <img src="../assets/ntsupplycompanylogo.png" alt="" />
      </div>
      <div class="bottom-ft-right">
        <h3>Links</h3>
        <a href="/"> Home </a>
        <a href="/#services"> Services </a>
        <a href="/projects"> Projects </a>
        <a href="/contactUs"> Contact us </a>
      </div>
      <div class="bottom-ft-right">
        <h3>About us</h3>
        <a href="/about"> Mission </a>
        <a href="/about"> Vision </a>
        <a href="/about"> Value </a>
      </div>
      <div class="bottom-ft-info">
        <h3>Info</h3>
        <p><i class="fa-solid fa-phone"></i><span>+250 783 290 624</span></p>
        <p>
          <i class="fa-solid fa-envelope"></i
          ><span>visionconstructionbureau@gmail.com</span>
        </p>
      </div>
      <div class="footer-social">
        <h3>Find us on</h3>
        <div>
          <a href="mailto:visionconstructionbureau@gmail.com" target="_blank">
            <span>
              <i class="fa-solid fa-envelope"></i>
            </span>
          </a>
          <a href="#">
            <span>
              <i class="fa-brands fa-instagram"></i>
            </span>
          </a>
          <a href="https://wa.me/250783290624" target="_blank">
            <span>
              <i class="fa-brands fa-whatsapp"></i>
            </span>
          </a>
          <a href="#">
            <span>
              <i class="fa-brands fa-twitter"></i>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <p>&copy; {{ currentYear }} NT SUPPLY COMPANY Limited</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.bottom-ft {
  background: rgb(59, 59, 59, 0.5);
  opacity: 0.9;
  padding: 40px;
  background-image: url("../assets/NTSCltd22.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
}

.bottom-ft-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bottom-ft-right,
.bottom-ft-info,
.footer-social {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.bottom-ft-right a {
  color: white;
  text-decoration: none;
  margin: 3px 7px;
  font-size: 14px;
}

.bottom-ft-right a:hover {
  text-decoration: underline;
}

h3 {
  color: white;
  font-weight: 500;
  margin-bottom: 10px;
}

.bottom-ft-info p {
  font-size: 14px;
  color: white;
  margin-bottom: 5px;
}
.bottom-ft-info p i {
  margin-right: 5px;
}

.footer-social h3 {
  margin-bottom: 20px;
}

.footer-social i {
  margin: 0 10px;
  color: white;
}

.footer-social span {
  border: 1px solid white;
  padding: 8px 1px;
  margin: 3px;
  border-radius: 50%;
}

.footer-bottom {
  color: #dddddd;
}

.footer-bottom p {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 100;
}

.bottom-ft-shaow {
  background: rgba(0, 0, 0, 0.89);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 400px) {
  .bottom-ft-left img {
    width: 210px;
  }
}
</style>