<template>
  <div class="contact-us">
    <div class="contact-cover-img">
      <img src="../assets/NTSCltd20.png" alt="" />
      <h1>Contact us</h1>
    </div>
    <h2 class="other-section-title">Get In Touch</h2>
    <div class="app-default-underline"></div>
    <div class="contact-us-container">
      <div class="contact-us-form">
        <form @submit="submitContactUs">
          <div class="name-phone-inputs-cont">
            <input
              type="text"
              placeholder="Full name"
              required
              v-model="fullName"
            />
            <input type="text" placeholder="Phone" required v-model="phone" />
          </div>
          <input type="email" placeholder="Email" required v-model="email" />
          <textarea placeholder="Message" required v-model="message"></textarea>
          <button
            type="submit"
            :disabled="true"
            title="This service is not available now, Please use the other way"
          >
            <div class="loader" v-if="isSubmitting"></div>
            SEND MESSAGE
          </button>
        </form>
        <p class="success-msg" v-if="isSubmitted">
          Message sent successfully, Thank you for contacting us.
        </p>
      </div>
      <div class="contact-us-info">
        <span>LOCATION</span>
        <p><i>Kigali/Rwanda</i></p>
        <span style="margin-top: 20px">EMAIL</span>
        <p><i>visionconstructionbureau@gmail.com</i></p>
        <span style="margin-top: 20px">PHONE</span>
        <p><i>+250 783 290 624</i></p>
        <div class="contact-us-social">
          <a href="mailto:visionconstructionbureau@gmail.com" target="_blank">
            <i class="fa-solid fa-envelope"></i>
          </a>
          <a href="#">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://wa.me/250783290624">
            <i class="fa-brands fa-whatsapp"></i>
          </a>
          <a href="#">
            <i class="fa-brands fa-twitter"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullName: "",
      phone: "",
      email: "",
      message: "",
      isSubmitting: false,
      isSubmitted: false,
    };
  },
  methods: {
    submitContactUs() {},
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.contact-us {
  padding-top: 80px;
}

.contact-cover-img {
  width: 100%;
}

.contact-cover-img img {
  width: 100%;
  height: 25vh;
}

.contact-cover-img h1 {
  position: absolute;
  top: 17%;
  left: 40px;
  color: white;
  -webkit-text-stroke: 1px black;
  font-size: 60px;
}

.other-section-title {
  padding-top: 30px;
}

.contact-us-container {
  width: 70%;
  display: flex;
  margin: 0 auto 30px;
  justify-content: space-between;
}

.contact-us-form {
  width: 55%;
  padding: 10px 5px;
}

.success-msg {
  color: #43ab39;
  background-color: rgb(61, 193, 61, 0.15);
  margin: 20px 0;
  padding: 5px;
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #0294ff;
  border-bottom-color: #b8d7ee;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  margin-right: 10px;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

input,
textarea {
  font-size: inherit;
  line-height: inherit;
  padding: 15px 14px;
  width: calc(100% - 28px);
  border: 1px solid #a9a9a9;
  margin-bottom: 30px;
  color: #202c38;
  outline: none;
}

textarea {
  resize: none;
  height: 100px;
}

.name-phone-inputs-cont {
  width: 100%;
  display: flex;
}

.name-phone-inputs-cont input:nth-child(1) {
  margin-right: 10px;
}
.name-phone-inputs-cont input:nth-child(2) {
  margin-left: 10px;
}
.contact-us-form form button {
  background: var(--primary-color);
  border: none;
  width: 100%;
  padding: 17px 14px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-info {
  width: 40%;
  padding: 10px 5px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-us-info span {
  font-weight: 500;
  font-size: 13px;
  color: #8a8a8a;
  margin-bottom: 3px;
}

.contact-us-info p {
  color: #525252;
}

.contact-us-social {
  margin-top: 30px;
}

.contact-us-social i {
  background: var(--primary-color);
  padding: 10px;
  color: white;
  margin: 2px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .contact-us-container {
    width: 75%;
  }
}
@media (max-width: 930px) {
  .contact-us-container {
    width: 80%;
  }
}
@media (max-width: 900px) {
  .name-phone-inputs-cont {
    flex-direction: column;
  }

  .name-phone-inputs-cont input:nth-child(1) {
    margin-right: 0;
  }
  .name-phone-inputs-cont input:nth-child(2) {
    margin-left: 0;
  }
}
@media (max-width: 770px) {
  .contact-us-container {
    flex-direction: column-reverse;
  }
  .contact-us-info {
    width: 100%;
  }
  .contact-us-form {
    width: 100%;
  }
}
</style>